import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Modal from "@mui/material/Modal";
import ReactPaginate from "react-paginate";
import originalData from "./victims.json";
import TextField from '@mui/material/TextField';

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import "./App.css";
function Copyright() {
  return (
    <Typography variant="body2" color="white" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://Remember-Gaza.com/">
        Remember Gaza
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const defaultTheme = createTheme();

defaultTheme.typography.h2 = {
  fontFamily: "Anton",
  fontSize: "4.75rem",
  letterSpacing: "-0.00833em",
  fontWeight: 300,
  lineHeight: 1.2,
};

defaultTheme.typography.h5 = {
  fontFamily: "fangsong",
  margin: 0,
  fontWeight: 400,
  fontSize: "1.5rem",
  lineHeight: 1.334,
  letterSpacing: "0em",
};

defaultTheme.typography.subtitle1 = {
  fontSize: '0.90rem',
  '@media (min-width:600px)': {
    fontSize: '0.90rem',
  },
  [defaultTheme.breakpoints.down('md')]: {
    fontSize: '0.75rem',
  },
};
defaultTheme.typography.h6 = {
  margin: 0,
  fontWeight: 500,
  fontSize: '1.25rem',
  lineHeight: 1.6,
  letterSpacing: '0.0075em',
    '@media (min-width:600px)': {
    fontSize: '0.90rem',
  },
  [defaultTheme.breakpoints.down('md')]: {
    fontSize: '1rem',
  },
};

const sortFunc = (a,b) => a.filled > b.filled ? -1 : 0

export default function Main() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = (id) => setOpen(id);
  const [data, setData] = React.useState(originalData.sort(sortFunc));
  const [category, setCategory] = React.useState();

  const onSearch = (event) => { 
    const query = event.target.value.toLowerCase(); // Convert the query to lowercase
  
    let filteredData = data;
    if (query) {
      filteredData = filteredData.filter((value) =>
        value.name.toLowerCase().includes(query) || value.id.toLowerCase().includes(query)
      ).sort(sortFunc);
    } else {
      filteredData = originalData;
    }
  
    setData(filteredData);
    setPagination({
      data: filteredData,
      offset: 0,
      numberPerPage: 102,
      pageCount: filteredData.length / 102,
      currentData: filteredData.slice(0, 102),
    });
  }
  const handleChange = (event) => {
    let filteredData = originalData;
    if (event.target.value) {
      filteredData = filteredData.filter((value) =>
        value.tags.includes(event.target.value)
      ).sort(sortFunc);
    }
      setData(filteredData);
      setPagination({
        data: filteredData,
        offset: 0,
        numberPerPage: 102,
        pageCount: filteredData.length / 102,
        currentData: filteredData.slice(0, 102),
      });
    setCategory(event.target.value);
  };
  const [pagination, setPagination] = useState({
    data: data,
    offset: 0,
    numberPerPage: 102,
    pageCount: 0,
    currentData: [],
  });
  useEffect(() => {
    setPagination((prevState) => ({
      ...prevState,
      pageCount: prevState.data.length / prevState.numberPerPage,
      currentData: prevState.data.slice(
        pagination.offset,
        pagination.offset + pagination.numberPerPage
      ),
    }));
  }, [pagination.numberPerPage, pagination.offset]);
  const handlePageClick = (event) => {
    const selected = event.selected;
    const offset = selected * pagination.numberPerPage;
    setPagination({ ...pagination, offset });
  };
  const handleClose = () => setOpen(false);
  const renderCard = (victim) => {
    let imageSrc;
    try {
      imageSrc = require("./pics/" + victim.id + ".jpg");
    } catch (err) {
      imageSrc = process.env.PUBLIC_URL + "/placeholder.jpg";
    }
    return (  
      <Grid item key={victim.id} xs={6} sm={4} md={2}>
        <Card sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
          <CardActionArea onClick={() => handleOpen(victim.id)}>
            <CardMedia
              component="div"
              sx={{
                // 16:9
                pt: "56.25%",
                height: "250px",
              }}
              image={imageSrc}
              on
              classes={{
                root: "grey",
              }}
            />
            <CardContent
              sx={{
                flexGrow: 1,
                padding: "8px !important",
                pb: "1px !important",
              }}
            >
              <Typography variant="subtitle1" component="subtitle1">
                {victim.name}, {victim.age ? isNaN(victim.age) ? "< 1" : victim.age : ""}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
    );
  };

  const selection = data.find((value) => value.id === open);
  const getImage = (open) => {
    let imageSrc;
    try {
      imageSrc = require("./pics/" + open + ".jpg");
    } catch (err) {
      imageSrc = process.env.PUBLIC_URL + "/placeholder.jpg";
    }
    return imageSrc
  }
  return (
    <ThemeProvider theme={defaultTheme}>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Container sx={{ py: 8 }} maxWidth="md">
          <Grid container>
            <Grid item xs={8} sm={4} md={2}>
              <Card
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  // width: 400,
                  background: "white",
                  // border: '2px solid #000',
                  boxShadow: 24,
                  // padding: 10,
                  minWidth: 300,
                }}
              >
                <CardMedia
                key={open}
                  component="div"
                  sx={{
                    height: "300px",
                    backgroundPositionY: "top",
                  }}
                  image={getImage(open)}
                />
                <CardContent>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    {selection ? selection.name : "N/A"}
                  </Typography>
                  <Typography variant="subtitle1">
                    {selection && selection.age
                      ? isNaN(selection.age)
                        ? "less than a year old"
                        : selection.age + " Years Old"
                      : "N/A"}
                  </Typography>
                  <Typography
                    id="modal-modal-description"
                    sx={{ mt: 2 }} paragraph
                  >{selection ? selection.comments: ""}</Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Modal>

      <CssBaseline />
      {/* <AppBar position="relative"> */}
      {/* <Toolbar> */}
      {/* <CameraIcon sx={{ mr: 2 }} /> */}
      {/* <Typography variant="h6" color="inherit" noWrap> */}
      {/* Remember Gaza */}
      {/* </Typography> */}
      {/* </Toolbar> */}
      {/* </AppBar> */}
      <main
        style={{
          background:
            "linear-gradient(0deg, rgba(169,14,4,1) 5%, rgba(204,34,22,1) 18%, rgba(40,7,4,1) 46%, rgba(0,0,0,1) 100%)",
        }}
      >
        {/* Hero unit */}
        <Box
          sx={{
            pt: 8,
            pb: 6,
            background:
              "linear-gradient(180deg, rgba(169,14,4,1) 7%, rgba(204,34,22,1) 69%, rgba(0,0,0,1) 100%);",
          }}
        >
          <Container maxWidth="md">
            <Typography
              component="h1"
              variant="h2"
              align="center"
              color="white"
              gutterBottom
            >
              REMEMBER GAZA
            </Typography>
            <Typography
              component="h1"
              variant="h2"
              align="center"
              color="white"
              gutterBottom
            >
              {" "}
              STOP THE GENOCIDE
            </Typography>
            <Typography variant="h5" align="center" color="white" paragraph>
              This is a memorial of the victims of the ongoing genocide in Gaza
              being conducted by the Israeli regime. So far, over 11 thousands
              deaths reported. Actual number is said to be higher than that. <br />
            </Typography>
            <Typography variant="h5" align="center" color="white" paragraph>
              Most are children and women.
            </Typography>
            <Typography variant="h5" align="center" color="white" paragraph>
              Contact us on our{" "}
              <a
                style={{
                  color: "white",
                  fontWeight: "bold",
                }}
                href="https://www.instagram.com/remembergaza/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Instagram
                <span style={{ marginLeft: "4px" }}>🔗</span>
              </a>{" "}
              to provide details about a victim.
              <strong>
                {" "}
                <br />
                We must have consent from a family member to publish the
                information.
              </strong>
            </Typography>

          </Container>
        </Box>

        <Container
          sx={{ py: 8 }}
          maxWidth="md"
        >
          {/* End hero unit */}
          <Grid container spacing={1}>
            <Grid item xs={12} textAlign={"center"}>
              <FormControl
                sx={{ m: 1, minWidth: 120, color: "white" }}
                size="small"
              >
                <InputLabel
                  id="demo-select-small-label"
                  sx={{ color: "white" }}
                >
                  Filter
                </InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={category}
                  label="Filter"
                  onChange={handleChange}
                  sx={{
                    color: "white",
                    borderColor: "white !important",
                  }}
                  classes={{ nativeInput: "filter", icon: "filter" }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={"man"}>Men</MenuItem>
                  <MenuItem value={"woman"}>Women</MenuItem>
                  <MenuItem value={"child"}>Children</MenuItem>
                  <MenuItem value={"baby"}>Babies</MenuItem>
                  <MenuItem value={"infant"}>Infants</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} textAlign={"center"}>
            <TextField 

            InputLabelProps={{
              sx: {
                color: "white"
              }
            }}
            InputProps={{
              sx: {
                color: "white"
              }
            }}
                  classes={{ label: "filter", icon: "filter" }}
                  id="outlined-basic" label="Search via Name or ID" variant="outlined" 
                  onChange={onSearch}/>
            </Grid>

            {pagination.currentData &&
              pagination.currentData.map((victim) => renderCard(victim))}

            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              breakLabel={".."}
              pageCount={pagination.pageCount}
              marginPagesDisplayed={1}
              pageRangeDisplayed={1}
              onPageChange={handlePageClick}
              activeClassName={"item active "}
              breakClassName={"item break-me "}
              containerClassName={"pagination"}
              disabledClassName={"disabled-page"}
              nextClassName={"item next "}
              pageClassName={"item pagination-page "}
              previousClassName={"item previous"}
            />
          </Grid>
        </Container>
      </main>
      {/* Footer */}
      <Box
        sx={{
          background:
            "linear-gradient(180deg, rgba(169,14,4,1) 5%, rgba(204,34,22,1) 34%, rgba(40,7,4,1) 100%, rgba(0,0,0,1) 100%)",
          p: 6,
        }}
        component="footer"
      >
        <Typography variant="h6" align="center" color="white" gutterBottom>
          REMEMBER GAZA
        </Typography>
        <Typography
          variant="subtitle1"
          align="center"
          color="white"
          component="p"
        >
          REMEMBER THE VICTIMS
        </Typography>
        <Copyright />
      </Box>
      {/* End footer */}
    </ThemeProvider>
  );
}
